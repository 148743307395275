import React from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis } from 'recharts';

import useSubscription from '@/hooks/account/useSubscription';
import { ChartDataBarModel } from '@/models/Metrics';

import Loading from '../utility/Loading';

const BarChartComponent = <T,>({
  data,
  xAxisKeyName,
  suffix,
}: {
  data: ChartDataBarModel<T>;
  xAxisKeyName: string;
  suffix: string;
}) => {
  const { isSubscribed } = useSubscription();

  const handleTooltip = (event: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (event.payload && event.payload[0]) {
      return (
        <div className="text-center graph-tooltip">
          {event.payload.map((item, index) => (
            <div key={index} className="d-flex">
              <p className="small pt0 pl8 text-left">
                {item.name}: {item.value}
                {suffix}
              </p>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <div data-testid="dashboard-line-chart">
        {data && (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart className="line-chart" data={data.data}>
              <CartesianGrid stroke="#383838" vertical={false} />
              <XAxis dataKey={xAxisKeyName || 'gender'} />
              {data.key.map((key, index) => (
                <Bar
                  key={key}
                  dataKey={key}
                  name={data.name[index]}
                  fill={data.color[index]}
                  barSize={16}
                  radius={[2, 2, 2, 2]}
                />
              ))}

              <Tooltip content={handleTooltip} cursor={{ fill: 'transparent' }} />
              <Legend verticalAlign="bottom" height={40} iconType="square" />
            </BarChart>
          </ResponsiveContainer>
        )}
        {!data && (
          <div className="centered-loading mt48 mb48" data-testid="pie-loading">
            <Loading size="small" />
          </div>
        )}
        {!isSubscribed && <div className="text-center">This is not real data</div>}
      </div>
    </>
  );
};

export default BarChartComponent;
