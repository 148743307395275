import { useEffect, useState } from 'react';

import { eachDayOfInterval, format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import { ChartDataModel } from '@/models/Metrics';

const useLineChartData = <T>({
  data,
  startDate,
  endDate,
}: {
  data?: {
    name: string;
    color: string;
    key: string;
    dataMap: Map<string, number>;
  }[];
  startDate?: string;
  endDate?: string;
}) => {
  const [chartData, setChartData] = useState<ChartDataModel<T>>();

  useEffect(() => {
    if (!data || !startDate) return;

    const allDataMapsEmpty = data.every(({ dataMap }) => dataMap.size === 0);
    if (allDataMapsEmpty) {
      setChartData({
        name: data.map((value) => value.name),
        color: data.map((value) => value.color),
        key: data.map((value) => value.key),
        data: [],
      });
      return;
    }

    const dateRange = eachDayOfInterval({
      start: new Date(startDate),
      end: endDate ? new Date(endDate) : new Date(DateTimes.yesterdayDate),
    });

    const preparedData = {
      name: data.map((value) => value.name),
      color: data.map((value) => value.color),
      key: data.map((value) => value.key),
      data: dateRange.map((date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const dayData = data.reduce(
          (acc, metric) => {
            acc[metric.key] = metric.dataMap.get(formattedDate) || 0;
            return acc;
          },
          {} as { [key: string]: number }
        );

        return { date: formattedDate, ...dayData } as T;
      }),
    };

    setChartData(preparedData);
  }, [data, endDate, startDate]);

  return chartData;
};

export default useLineChartData;
