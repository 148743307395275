import React from 'react';

import { format } from 'date-fns';
import { Area, AreaChart, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, YAxis } from 'recharts';

import { brandColors, brandColorsAreaFaded } from '@/constants/BrandColors';
import { ChartOpacity } from '@/constants/ChartOpacity';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { MetricsDataModel } from '@/models/Metrics';

import Loading from '../utility/Loading';

const AreaChartTileComponent = ({ data, color, name }: { data: MetricsDataModel[]; color: string; name: string }) => {
  const brandColor = brandColors[`${color}-color`];
  const areaColor = brandColorsAreaFaded[`${color}-color`];

  const handleTooltip = (event: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (event.payload && event.payload[0]) {
      return (
        <div className="graph-tooltip text-center">
          <div className="d-flex">
            <p className="small pt0 pl8 text-left capitalize">
              {event.payload[0] && event.payload[0].name}:{' '}
              {event.payload[0] && numberFormatter(Number(event?.payload[0]?.value), 2)}
            </p>
          </div>
          {event?.payload[0]?.payload.date && (
            <p className="small text-faded">{format(new Date(event?.payload[0]?.payload.date), 'dd/MM/yy')}</p>
          )}
        </div>
      );
    }
  };

  return (
    <div data-testid="">
      {data && data.length > 0 && (
        <ResponsiveContainer width="100%" height={110}>
          <AreaChart className="area-chart" data={data}>
            <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
            <Area
              className="tile-area-chart"
              type="monotone"
              dataKey="value"
              name={name}
              strokeOpacity={ChartOpacity.full}
              strokeWidth={2}
              fillOpacity={ChartOpacity.mid}
              stroke={`url(#line-gradient-${color}) ${brandColor[0]}`}
              fill={`url(#area-gradient-${color}) ${areaColor[0]}`}
            />

            <Tooltip content={handleTooltip} />
            <ReferenceLine y={0} stroke={brandColor[0]} strokeWidth={2} />
          </AreaChart>
        </ResponsiveContainer>
      )}

      {!data && (
        <div className="centered-loading mt20 mb20" data-testid="pie-loading">
          <Loading size="small" />
        </div>
      )}
    </div>
  );
};

export default AreaChartTileComponent;
