import React from 'react';

import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';

import { platformNameConverter } from '@/formatters/PlatformNameConverter';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useSubscription from '@/hooks/account/useSubscription';
import { ChartDataPieModel } from '@/models/Metrics';

import ChartGradients from '../../constants/ChartGradients';
import Loading from '../utility/Loading';

const PieChartComponent = ({
  data,
  suffix,
  isLegend = true,
  isOverview = false,
}: {
  data?: ChartDataPieModel[];
  suffix?: string;
  isLegend?: boolean;
  isOverview?: boolean;
}) => {
  const { isSubscribed } = useSubscription();

  const handleTooltip = (event: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (event.payload && event.payload[0]) {
      return (
        <div className="text-center graph-tooltip p8">
          {isOverview && (
            <>
              <div className="d-flex jc-center gap8">
                <img
                  src={`/images/logos/${event.payload[0].payload.name === 'youtube_channel' ? 'youtube' : event.payload[0].payload.name}-logo.svg`}
                  alt=""
                />
                <p>
                  {event.payload[0].value}
                  {suffix}
                </p>
              </div>
              <div className="d-flex mt2 jc-center">
                <p className="small text-left capitalize">
                  {platformNameConverter(event.payload[0].payload.metric as string)}:{' '}
                  {numberFormatter(event.payload[0].payload.actualValue)}
                </p>
              </div>
            </>
          )}
          {!isOverview &&
            event.payload.map((item, index) => (
              <div key={index} className="d-flex">
                <p className="small pt0 pl8 text-left">
                  {platformNameConverter(item.name as string)}: {item.value}
                  {suffix}
                </p>
              </div>
            ))}
        </div>
      );
    }
  };

  return (
    <>
      <div data-testid="dashboard-pie-chart">
        <ChartGradients />
        {data && (
          <ResponsiveContainer width="100%" height={350}>
            <PieChart className="pie-chart-wrapper">
              <Pie
                data={data}
                startAngle={-270}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={'70%'}
                outerRadius={'100%'}
                fill="url(#blue-gradient)"
                stroke="none"
                paddingAngle={1}
                animationBegin={0}
              />

              <Tooltip content={handleTooltip} />
              {isLegend && (
                <Legend
                  iconType="square"
                  payload={data.map((item) => ({
                    id: item.name,
                    type: 'square',
                    value: `${item.name} (${item.value}${suffix})`,
                    color: item.fill,
                  }))}
                />
              )}
            </PieChart>
          </ResponsiveContainer>
        )}
        {!data && (
          <div className="centered-loading mt48 mb48" data-testid="pie-loading">
            <Loading size="small" />
          </div>
        )}
        {!isSubscribed && !isOverview && <div className="text-center">This is not real data</div>}
      </div>
    </>
  );
};

export default PieChartComponent;
